import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { withTranslation } from 'react-i18next';
import Breadcrumb from '../../../shared/Breadcrumb';
import UserModal from './userModal';
import Sidebar from './Sidebar';
import { url } from '../../../../helper/helper';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Files from 'react-files';
import Send from '../../../../images/send.svg';
import Attachment from '../../../../images/Vector.svg';
import { getData, postDataWithToken } from '../../../../helper/api';
import socketIO from 'socket.io-client';
import { io } from 'socket.io-client';
import './PmsMessage.scss';
import useChat from './useChat';
import { dateFunc } from '../../../../helper/dateFunc/date';
//import { useSocket } from './useEcho';

//const socket = socketIO.connect('http://localhost:4000', {
//autoConnect: false,
//});
// const socket = io('https://appv2.proppu.com');
const CONNECTION = 'http://localhost:8000';

const PmsMessages = ({ t }) => {
  const params = useParams();
  const lang = localStorage.getItem('_lang');
  const [name, setName] = useState('');
  const [count, setCount] = useState([]);
  const [image, setImage] = useState('');
  const [isOpenModalUser, setIsOpenModalUser] = useState(false);
  const {
    messages,
    sendMessages,
    uploadFile,
    handleTyping,
    setPersonTyping,
    personTyping,
    typing,
    setTyping,
    onKeyUp,
    users,
    personId,
    fetchData,
    groupId,
    id,
    sidebarProjects,
    setId,
    setGroupId,
  } = useChat();

  const [newMessage, setNewMessage] = useState('');
  const [windowSize, setWindowSize] = useState(0);
  const [fileAttach, setFileAttach] = useState();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    loadData();
  }, [name, image]);
  const lastMessageRef = useRef(null);

  const loadData = async () => {
    const token = localStorage.getItem('token');
    await getData(`${url}/api/account`, token).then((res) => {
      if (res !== undefined) {
        const { data } = res;
        setName(data[0]?.full_name);
        setUserId(data[0]?.id);
        setImage(data[0].company_logo_path + data[0].company_logo);
      }
    });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    sendMessages(newMessage, localStorage.getItem('Login_user_id'), name);
    setNewMessage('');
    //onKeyUp();
  };

  const sendFile = (e) => {
    uploadFile(e, localStorage.getItem('Login_user_id'), name);
  };

  useEffect(() => {
    return lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const convertBufferToImage = (arrayBuffer) => {
    //return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    var binary = '';
    var bytes = new Uint8Array(arrayBuffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  //let arr = [...new Set(users)];

  const uniqueArray = users.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      users.findIndex((obj) => {
        return JSON.stringify(obj) === _value;
      })
    );
  });

  const sidebarPerson = (arr) => {
    if (arr !== undefined) {
      return arr?.filter(({ name, image }) => {
        return name, image;
      });
    }
  };

  const handleWindowResize = useCallback((event) => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    handleWindowResize();
  }, []);

  return (
    <>
      {windowSize < 575 ? (
        <div
          className='main-content'
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Sidebar
            dataFromParent={window.location.pathname}
            getUser={setId}
            setGroupId={setGroupId}
            groupId={groupId}
            fetchData={fetchData}
            name={
              sidebarPerson(uniqueArray)?.find((nam) => nam.name !== name)?.name
            }
            image={image}
            messages={sidebarProjects}
            setId={setId}
            id={id}
          />

          <div className='page-content' style={{ padding: '0px' }}>
            <div
              className='add-chat'
              onClick={() => {
                setIsOpenModalUser(true);
              }}
            >
              &#43; Add user
            </div>
            <div className='container-fluid'>
              <div className='chat'>
                <div className='chat__sidebar'>
                  <div className='message__container'>
                    {messages &&
                      messages?.map((message, idx) => {
                        if (message.message_group_id === groupId) {
                          return message?.message_user_id ===
                            Number(localStorage.getItem('Login_user_id')) ? (
                            <div className='message__chats' key={idx}>
                              <p className='sender__name'>
                                You &#9679;{' '}
                                {dateFunc(message?.created_at, lang)}
                              </p>
                              <div
                                className='message__sender'
                                ref={lastMessageRef}
                              >
                                <p>{message?.message_text}</p>
                                <p>{message?.created_at}</p>
                                {message.message_image && (
                                  <img
                                    style={{
                                      width: '150px',
                                      margin: 'auto',
                                      display: 'block',
                                    }}
                                    // src={`data:image/png;base64,${convertBufferToImage(
                                    //   message.file
                                    // )}`}
                                    src={
                                      message.chat_file_path +
                                      message.message_image
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className='message__chats' key={idx}>
                              <p>
                                {message.user.full_name} &#9679;{' '}
                                {dateFunc(message?.created_at, lang)}
                              </p>
                              <div
                                className='message__recipient'
                                ref={lastMessageRef}
                              >
                                <p>{message?.message_text}</p>
                                <p>{message?.created_at}</p>
                                {message.message_image && (
                                  <img
                                    style={{
                                      width: '150px',
                                      margin: 'auto',
                                      display: 'block',
                                    }}
                                    // src={`data:image/png;base64,${convertBufferToImage(
                                    //   message.file
                                    // )}`}
                                    src={
                                      message.chat_file_path +
                                      message.message_image
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}
                    {/*This shows messages received by you*/}

                    {/*This is triggered when a user is typing*/}
                    <div className='message__status'>
                      {fileAttach && (
                        <img width={150} src={fileAttach[0].preview.url} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='chat__footer'>
              <p>
                {!typing &&
                personTyping &&
                personId !== Number(localStorage.getItem('Login_user_id'))
                  ? `${personTyping} is typing...`
                  : ''}
              </p>

              <form className='form' onSubmit={sendMessage}>
                <input
                  type='text'
                  placeholder={t('myBusiness.chat.write_message')}
                  className='message'
                  id='message'
                  autoComplete='off'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={() => handleTyping(name)}
                  //onBlur={() => onKeyUp()}
                />

                <div
                  style={{
                    display: 'flex',
                    //width: '34px',
                    //height: '35px',
                    //justifyContent: 'space-around',
                    alignItems: 'center',
                    backgroundColor: ' #F3F3F3',
                    marginRight: '2rem',
                  }}
                >
                  <Files
                    className='files-dropzone'
                    onChange={(e) => sendFile(e)}
                    // onError={(e) => onFilesError(e)}
                    accepts={[
                      'image/gif',
                      '.doc ',
                      '.docx',
                      'image/jpeg',
                      'image/png',
                      'image/jpg',
                      '.svg',
                      '.pdf',
                    ]}
                    multiple={true}
                    maxFileSize={10000000}
                    minFileSize={10}
                    // clickable={edit}
                  >
                    <img
                      src={Attachment}
                      alt='attachment'
                      style={{ width: '20px' }}
                    />
                    {/* <div style={{ textAlign: 'center', marginLeft: '2rem' }}>
                    Attach
                  </div> */}
                  </Files>
                </div>

                <button className='sendBtn' type='submit'>
                  <img
                    src={Send}
                    alt='send-button'
                    onClick={sendMessage}
                    className='send-image'
                  />
                </button>
              </form>
              <UserModal
                show={isOpenModalUser}
                handleClose={() => {
                  setIsOpenModalUser(false);
                }}
                params={params}
                groupId={groupId}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='main-content'>
          <Sidebar
            dataFromParent={window.location.pathname}
            setGroupId={setGroupId}
            getUser={setId}
            fetchData={fetchData}
            name={
              sidebarPerson(uniqueArray)?.find((nam) => nam.name !== name)?.name
            }
            image={image}
            messages={sidebarProjects}
            setId={setId}
            id={id}
          />

          <div className='page-content' style={{ overflow: 'visible' }}>
            <div
              className='add-chat'
              onClick={() => {
                setIsOpenModalUser(true);
              }}
            >
              &#43; Add user
            </div>
            <div className='container-fluid'>
              <div className='chat'>
                <div className='chat__sidebar'>
                  <div className='message__container'>
                    {messages &&
                      messages?.map((message, idx) => {
                        if (message.message_group_id === groupId) {
                          return message?.message_user_id ===
                            Number(localStorage.getItem('Login_user_id')) ? (
                            <div className='message__chats' key={idx}>
                              <p className='sender__name'>
                                You &#9679;{' '}
                                {dateFunc(message?.created_at, lang)}
                              </p>
                              <div
                                className='message__sender'
                                ref={lastMessageRef}
                              >
                                <p>{message?.message_text}</p>

                                {message.message_image && (
                                  <img
                                    style={{
                                      width: '150px',
                                      margin: 'auto',
                                      display: 'block',
                                    }}
                                    // src={`data:image/png;base64,${convertBufferToImage(
                                    //   message.file
                                    // )}`}
                                    src={
                                      message.chat_file_path +
                                      message.message_image
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className='message__chats' key={idx}>
                              <p>
                                {message?.user?.full_name} &#9679;{' '}
                                {dateFunc(message?.created_at, lang)}
                              </p>
                              <div
                                className='message__recipient'
                                ref={lastMessageRef}
                              >
                                <p>{message.message_text}</p>

                                {message.message_image && (
                                  <img
                                    style={{
                                      width: '150px',
                                      margin: 'auto',
                                      display: 'block',
                                    }}
                                    // src={`data:image/png;base64,${convertBufferToImage(
                                    //   message.file
                                    // )}`}
                                    src={
                                      message.chat_file_path +
                                      message.message_image
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }
                      })}

                    {/*This shows messages received by you*/}

                    {/*This is triggered when a user is typing*/}
                    <div className='message__status'>
                      {fileAttach && (
                        <img width={150} src={fileAttach[0].preview.url} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='chat__footer'>
              <p>
                {!typing &&
                personTyping &&
                personId !== Number(localStorage.getItem('Login_user_id'))
                  ? `${personTyping} is typing...`
                  : ''}
              </p>

              <form className='form' onSubmit={sendMessage}>
                <input
                  type='text'
                  placeholder={t('myBusiness.chat.write_message')}
                  className='message'
                  id='message'
                  autoComplete='off'
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={() => handleTyping(name)}
                  //onBlur={() => onKeyUp()}
                />

                <div
                  style={
                    {
                      //display: 'flex',
                      //alignItems: 'center',
                      //backgroundColor: ' #F3F3F3',
                      // marginRight: '2rem',
                    }
                  }
                >
                  <Files
                    className='files-dropzone'
                    onChange={(e) => sendFile(e)}
                    // onError={(e) => onFilesError(e)}
                    accepts={[
                      'image/gif',
                      '.doc ',
                      '.docx',
                      'image/jpeg',
                      'image/png',
                      'image/jpg',
                      '.svg',
                      '.pdf',
                    ]}
                    multiple={true}
                    maxFileSize={10000000}
                    minFileSize={10}
                    // clickable={edit}
                  >
                    <img
                      src={Attachment}
                      alt='attachment'
                      style={{ width: '25px' }}
                    />
                    {/* <div style={{ textAlign: 'center', marginLeft: '2rem' }}>
                    Attach
                  </div> */}
                  </Files>
                </div>

                <button className='sendBtn' type='submit'>
                  <img
                    src={Send}
                    alt='send-button'
                    onClick={sendMessage}
                    className='send-image'
                  />
                </button>
              </form>
              <UserModal
                show={isOpenModalUser}
                handleClose={() => {
                  setIsOpenModalUser(false);
                }}
                params={params}
                groupId={groupId}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(PmsMessages);
