import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import arrow from '../../../../images/arrow-left.svg';
import NewChatSidebar from './NewChatSidebar';
import { useParams } from 'react-router-dom';
import i18n from '../../../../locales/i18n';
import { Translation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'reactstrap';
import { url } from '../../../../helper/helper';
import { withRouter } from 'react-router';
import './PmsMessage.scss';
import { getData, postDataWithToken } from '../../../../helper/api';

const Sidebar = ({ setGroupId, fetchData, messages, setId, id }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggle = (e) => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backDropClickHandler = () => {
    setSideDrawerOpen(false);
  };
  const addScroll = () => {
    return { overflowY: 'scroll', height: 'calc(100vh - 34px)' };
  };

  useEffect(() => {
    addScroll();
  }, [messages]);
  let backdrop;
  if (sideDrawerOpen) {
    backdrop = <NewChatSidebar close={backDropClickHandler} />;
  }

  return (
    <div className='sidebar-message' style={addScroll()}>
      {/* <NewChatSidebar
        show={sideDrawerOpen}
        backDropClickHandler={backDropClickHandler}
        messagesList={messages}
        fetchData={fetchData}
      /> */}
      {backdrop}
      <div className='wraper'>
        <div className='scroll'></div>
        <ul className='nav flex-column sidebar-list'>
          <div className='sidebar-header'>
            <div className='ellipse'>
              <Link to='/manage-projects'>
                <img src={arrow} alt='' className='arrow' />
              </Link>
            </div>
            <div className='add-chat' onClick={drawerToggle}>
              &#43; New chat
            </div>
          </div>
          {messages?.map((message) => {
            return (
              <li
                onClick={() => {
                  setId(message.group_id);
                  setGroupId(message.group_id);
                  fetchData(message.group_id);
                }}
                key={message.id}
                className={
                  message.group_id === id ? 'nav-item active' : 'nav-item'
                }
              >
                <div
                  style={{
                    display: 'flex',
                    borderBottom: '1px solid  #F2F4F3',
                  }}
                >
                  <div
                    className='avatar'
                    style={{
                      width: '66px',
                      margin: 'auto',
                      paddingLeft: '1rem',
                    }}
                  >
                    <img
                      className='rounded-circle'
                      //src={names?.find((nam) => nam.name !== name)?.image}
                      alt=''
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {/* <Link className='nav-link' style={{ padding: '5px' }}>
                      {name ? name : ''}
                    </Link> */}
                    <p className='nav-link-text' style={{ padding: '5px' }}>
                      {message.group_name}
                    </p>
                    <p
                      className='nav-link-text'
                      style={{ marginRight: '5px' }}
                      onClick={fetchData}
                    >
                      {message.unread_message_count > 0 ? (
                        <span className='badge badge-danger'>
                          {message?.unread_message_count}
                        </span>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
