import { useEffect, useRef, useState, useCallback } from 'react';
import Echo from 'laravel-echo';
import { useParams } from 'react-router-dom';
import { url } from '../../../../helper/helper';
import io from 'socket.io-client';
//import socketIOClient from 'socket.io-client';
import Pusher from 'pusher-js';
import { getData, postData, postDataWithToken } from '../../../../helper/api';
const NEW_CHAT_MESSAGE_EVENT = '.project-message'; // Name of the event
//const SOCKET_SERVER_URL = 'http://localhost:8000';
//window.io = io;
window.Pusher = require('pusher-js');
const useChat = () => {
  const params = useParams();

  const [messages, setMessages] = useState([]); // Sent and received messages
  const [typing, setTyping] = useState(false);
  const [personTyping, setPersonTyping] = useState('');
  const [groupId, setGroupId] = useState(null);
  const [id, setId] = useState(null);
  const [sidebarProjects, setSidebarProjects] = useState([]);
  const [personId, setPersonId] = useState('');
  const [users, setUsers] = useState([]);
  const socketRef = useRef();
  const token = localStorage.getItem('token');
  var clearInterval1 = 900; //0.9 seconds
  var clearTimerId1;
  var canPublish = true;
  var throttleTime = 200;
  useEffect(() => {
    const pusher = new Pusher('7efd8ba5abaf94c7ccc4', {
      cluster: 'ap2',
    });
    const echo = new Echo({
      broadcaster: 'pusher',
      key: '7efd8ba5abaf94c7ccc4',
      authEndpoint: `${url}/api/pusher/auth`,
      cluster: 'ap2',
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          //'Access-Control-Allow-Origin': '*',
        },
      },
      useTLS: true,
    });
    socketRef.current = pusher.subscribe(`private-chat-room-${groupId}`);
    socketRef.current = pusher.subscribe(`private-typing-channel-${groupId}`);
    // socketRef.current.bind(NEW_CHAT_MESSAGE_EVENT, function (data) {
    //   console.log(data);
    // });
    // Listens for incoming messages
    echo
      .private(`private-chat-room-${groupId}`)
      .listen(NEW_CHAT_MESSAGE_EVENT, (message) => {
        const incomingMessage = {
          ...message.data.message[0],
          ownedByCurrentUser: message.group_id === groupId,
        };

        setMessages((messages) => [...messages, incomingMessage]);
        getSidebarProjects();
      });

    echo
      .private(`private-typing-channel-${groupId}`)
      .listen('.typing-event', (message) => {
        setPersonId(message.user_id);
        setPersonTyping(message.user);

        clearTimeout(clearTimerId1);
        clearTimerId1 = setTimeout(function () {
          setPersonTyping('');
        }, clearInterval1);
      });

    // Destroys the socket reference when the connection is closed
    return () => {
      pusher.unsubscribe(`private-chat-room-${groupId}`);
    };
  }, [groupId]);

  // Sends a message to the server that
  // forwards it to all users in the same room
  const sendMessages = async (messageBody, id, name, file) => {
    socketRef.current.bind(NEW_CHAT_MESSAGE_EVENT, {
      message_text: messageBody,
      id: id,
      name: name,
      attachment: file,
      group_id: groupId,
    });
    setTyping(false);
    setPersonTyping('');

    const data = new FormData();
    data.set('message_text', messageBody);
    data.set('attachment', file !== undefined ? file[0] : '');
    data.set('group_id', groupId);
    data.set('name', name);
    data.set('id', id);
    await postDataWithToken(
      `${url}/api/project/save_message`,
      data,
      token
    ).then((res) => {
      //console.log(res);
    });
  };
  const uploadFile = async (file, id, name) => {
    socketRef.current.bind(NEW_CHAT_MESSAGE_EVENT, {
      attachment: file[0],
      id: id,
      group_id: groupId,
      name: name,
    });
    setTyping(false);
    setPersonTyping('');
    const data = new FormData();
    data.set('attachment', file[0]);
    data.set('group_id', groupId);
    data.set('name', name);
    data.set('id', id);
    await postDataWithToken(
      `${url}/api/project/save_message`,
      data,
      token
    ).then((res) => {
      //console.log(res);
    });
  };

  const handleTyping = async (name) => {
    socketRef.current.bind('.typing-event', {
      name: name,
    });
    setTyping(true);
    setPersonTyping(name);
    const data = new FormData();
    data.set('group_id', groupId);
    data.set('name', name);
    data.set('isTyping', true);
    if (canPublish) {
      await postDataWithToken(`${url}/api/typing`, data, token).then((res) => {
        //console.log(res);
      });
    }
    canPublish = false;
    setTimeout(function () {
      canPublish = true;
    }, throttleTime);
  };
  // const onKeyUp = async () => {
  //   socketRef.current.bind('.typing-event', {
  //     name: '',
  //   });
  //   setTyping(false);
  //   setPersonTyping('');
  //   const data = new FormData();
  //   data.set('group_id', groupId);
  //   data.set('name', '');
  //   data.set('isTyping', false);
  //   await postDataWithToken(`${url}/api/typing`, data, token).then((res) => {
  //     //console.log(res);
  //   });
  // };
  const fetchData = async (id) => {
    await getData(
      `${url}/api/project/get_project_message_new/${id}`,
      token
    ).then((messages) => {
      setMessages(messages?.data);
    });
    getSidebarProjects();
  };

  const getSidebarProjects = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('project_id', params?.id);
    await postDataWithToken(`${url}/api/project/chat`, data, token).then(
      (respone) => {
        const { data } = respone;

        setSidebarProjects(data);
      }
    );
  };

  const getSidebarIds = async () => {
    const token = localStorage.getItem('token');
    const data = new FormData();
    data.set('project_id', params?.id);
    await postDataWithToken(`${url}/api/project/chat`, data, token).then(
      (respone) => {
        const { data } = respone;

        setGroupId(
          data?.find((res) => res?.project_id === Number(params?.id))?.group_id
        );

        setId(
          data?.find((res) => res?.project_id === Number(params?.id))?.group_id
        );
      }
    );
  };

  useEffect(() => {
    fetchData(groupId);
  }, [groupId]);

  useEffect(() => {
    getSidebarProjects();
    getSidebarIds();
  }, []);
  console.log(messages);
  return {
    messages,
    sendMessages,
    uploadFile,
    handleTyping,
    setPersonTyping,
    personTyping,
    typing,
    setTyping,
    //onKeyUp,
    users,
    personId,
    fetchData,
    groupId,
    id,
    sidebarProjects,
    setId,
    setGroupId,
  };
};
export default useChat;
