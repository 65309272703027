import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import arrow from '../../../../images/arrow-left.svg';
import downArrow from '../../../../images/arrow-down.svg';
import contact from '../../../../images/contact.svg';
import { getData } from '../../../../helper/api';

const AccordionItem = ({
  messages,
  setId,
  id,
  isExpanded,
  addScroll,
  fetchData,
}) => {
  const lang = localStorage.getItem('_lng');
  return (
    <>
      {messages?.map((message) => {
        return (
          <div className='accord-box'>
            <div className='accord-heading'>
              <div className='accord__container'></div>
            </div>
            {isExpanded ? (
              <div className='sidebar-message'>
                <li
                  onClick={() => {
                    setId(message.group_id);
                    fetchData(message.project_id);
                  }}
                  key={message.id}
                  className={
                    message.group_id === id ? 'nav-item active' : 'nav-item'
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: '1px solid  #F2F4F3',
                    }}
                  >
                    {/* <div
                      className='avatar'
                      style={{
                        width: '66px',
                        margin: 'auto',
                        paddingLeft: '1rem',
                      }}
                    >
                      <img
                        className='rounded-circle'
                        src='https://appv2.proppu.com/proppu/public/images/marketplace/company_logo/1684133415_%E2%80%94Pngtree%E2%80%94travel_world_logo_5058531.png'
                        alt=''
                      />
                    </div> */}
                    <div style={{ width: '100%' }}>
                      <Link className='nav-link' style={{ padding: '5px' }}>
                        {message?.group_name}
                      </Link>
                      {/* <p className='nav-link-text' style={{ padding: '5px' }}>
                        {message.text}
                      </p> */}
                    </div>
                  </div>
                </li>
              </div>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

const NewChatSidebar = ({
  messagesList,
  show,
  backDropClickHandler,
  fetchData,
}) => {
  const [id, setId] = useState(1);
  const [searchField, setSearchField] = useState('');
  const [isExpanded, setIsExpanded] = useState({
    activeProjects: false,
    completedProjects: false,
    //client: false,
    //resource: false,
  });

  const addScroll = () => {
    return { overflowY: 'scroll', height: 'calc(100vh - 34px)' };
  };

  useEffect(() => {
    addScroll();
  }, [messagesList]);
  let drawerClasses = 'side-drawer-chat';
  if (show) {
    drawerClasses = 'side-drawer-chat open';
  }

  const filterContacts = (contacts) => {
    return contacts?.filter((contact) =>
      contact?.group_name.toLowerCase().includes(searchField.toLowerCase())
    );
  };

  return (
    <div className={drawerClasses}>
      <div className='wraper' style={addScroll()}>
        <div className='scroll'></div>
        <ul className='nav flex-column sidebar-list'>
          <div className='sidebar-header-new'>
            <div className='ellipse' onClick={backDropClickHandler}>
              <Link>
                <img src={arrow} alt='' className='arrow' />
              </Link>
            </div>
            <div className='add-chat'>New chat</div>
          </div>
          <div>
            <input
              type='text'
              className='search-contacts'
              placeholder={'Search contacts'}
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
          <div className='sidebar-header-contact'>
            <div className='ellipse'>
              <Link>
                <img
                  src={contact}
                  alt='contact'
                  className='contact'
                  //onClick={backDropClickHandler}
                />
              </Link>
            </div>
            <div className='new-group'>New group</div>
          </div>
          <div
            className='projects'
            onClick={() => {
              setIsExpanded({
                ...isExpanded,
                activeProjects: !isExpanded.activeProjects,
                completedProjects: false,
                // client: false,
                // resource: false,
              });
            }}
          >
            <div className='projects-title'>Active Projects</div>
            <div className='projects-image'>
              <img
                className='down-arrow'
                src={downArrow}
                slt='down arrow'
                style={{
                  transform: !isExpanded.activeProjects
                    ? 'rotateZ(-90deg)'
                    : '',
                }}
              />
            </div>
          </div>
          <AccordionItem
            isExpanded={isExpanded.activeProjects}
            messages={filterContacts(
              messagesList?.filter(
                (messages) => messages?.active_projects_count === 1
              )
            )}
            setId={setId}
            id={id}
            addScroll={addScroll}
            fetchData={fetchData}
          />
          <div
            className='projects'
            onClick={() => {
              setIsExpanded({
                ...isExpanded,
                completedProjects: !isExpanded.completedProjects,
                activeProjects: false,
                // client: false,
                // resource: false,
              });
            }}
          >
            <div className='projects-title'>Completed Projects</div>
            <div className='projects-image'>
              <img
                className='down-arrow'
                src={downArrow}
                slt='down arrow'
                style={{
                  transform: !isExpanded.completedProjects
                    ? 'rotateZ(-90deg)'
                    : '',
                }}
              />
            </div>
          </div>
          <AccordionItem
            isExpanded={isExpanded.completedProjects}
            messages={filterContacts(
              messagesList?.filter(
                (messages) => messages?.completed_projects_count === 1
              )
            )}
            setId={setId}
            id={id}
            addScroll={addScroll}
            fetchData={fetchData}
          />

          {/* <div
            className='projects'
            onClick={() => {
              setIsExpanded({
                ...isExpanded,
                client: !isExpanded.client,
                projects: false,
                resource: false,
              });
            }}
          >
            <div className='projects-title'>Client</div>
            <div className='projects-image'>
              <img
                className='down-arrow'
                src={downArrow}
                slt='down arrow'
                style={{
                  transform: !isExpanded.client ? 'rotateZ(-90deg)' : '',
                }}
              />
            </div>
          </div> */}
          {/* <AccordionItem
            isExpanded={isExpanded.client}
            //messages={filterContacts(messagesList)}
            setId={setId}
            id={id}
            addScroll={addScroll}
          /> */}
          {/* <div
            className='projects'
            onClick={() => {
              setIsExpanded({
                ...isExpanded,
                resource: !isExpanded.resource,
                projects: false,
                client: false,
              });
            }}
          >
            <div className='projects-title'>Resource</div>
            <div className='projects-image'>
              <img
                className='down-arrow'
                src={downArrow}
                slt='down arrow'
                style={{
                  transform: !isExpanded.resource ? 'rotateZ(-90deg)' : '',
                }}
              />
            </div>
          </div> */}
          {/* <AccordionItem
            isExpanded={isExpanded.resource}
            //messages={filterContacts(messagesList)}
            setId={setId}
            id={id}
            addScroll={addScroll}
          /> */}
        </ul>
      </div>
    </div>
  );
};
export default NewChatSidebar;
